import { FaGithub, FaYoutube, FaLinkedin, FaBook, FaFolder, FaCoffee, FaNewspaper } from 'react-icons/fa';
import { SiGooglescholar, SiVelog } from 'react-icons/si';

const iconComponents = {
  github: FaGithub,
  scholar: SiGooglescholar,
  youtube: FaYoutube,
  linkedin: FaLinkedin,
  blog: SiVelog,
  book: FaBook,
  portfolio: FaFolder,
  coffee: FaCoffee,
  newsletter: FaNewspaper
};

const SocialLink = ({ href, icon, text, color }) => {
  const IconComponent = iconComponents[icon];
  
  return (
    <a
      href={href}
      target="_blank"
      rel="noopener noreferrer"
      className="flex items-center gap-2 px-4 py-2 rounded-lg transition-colors duration-300 hover:opacity-80"
      style={{ backgroundColor: color, color: '#ffffff' }}
    >
      <IconComponent className="w-5 h-5" />
      <span>{text}</span>
    </a>
  );
};

export default SocialLink; 