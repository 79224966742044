import React from 'react';
import SocialLink from './components/SocialLink';
import ProjectCard from './components/ProjectCard';
import LanguageSelector from './components/LanguageSelector';
import { useLanguage } from './context/LanguageContext';
import { translations } from './config/translations';
import { FaFolder } from 'react-icons/fa';

function App() {
  const { language } = useLanguage();
  const t = translations[language];

  return (
    <div className="min-h-screen bg-gray-50">
      <LanguageSelector />
      
      {/* Header Section */}
      <header className="container mx-auto px-6 lg:px-8 py-7 md:py-24">
        <div className="flex flex-col md:flex-row items-start gap-12 max-w-6xl mx-auto">
          <div className="w-40 md:w-48">
            <img 
              src={process.env.PUBLIC_URL + '/img.jpeg'} 
              alt="Profile" 
              className="w-full aspect-[3/4] rounded-2xl object-cover shadow-md"
            />
          </div>
          <div className="flex-1 space-y-2">
            <h1 className="text-4xl font-semibold text-gray-900 tracking-tight">
                {t.name}
                <span className="ml-2 text-xl text-gray-400">{t.nickname}</span>
            </h1>
            <div className="flex items-center space-x-3">
              {/* <p className="inline-block text-lg text-gray-600 p b-2">{t.email}</p> */}
              <a 
                href="mailto:kojunseo@icloud.com" 
                className="inline-block text-lg text-blue-600 hover:text-blue-700 hover:underline pb-2"
              >
                kojunseo@icloud.com
              </a>
            </div>
            <div className="flex items-center space-x-3">
              <a href="https://team.gangnamunni.com/" target="_blank" rel="noopener noreferrer" className="flex items-center space-x-3">
                <img src={process.env.PUBLIC_URL + '/hp-logo.jpeg'} alt="Logo" className="w-5 h-5" />
                <p className="text-lg text-orange-500 font-medium hover:text-orange-600">{t.team}</p>
              </a>
              <span className="text-gray-300">|</span>
              <p className="text-lg text-gray-900">{t.role}</p>
            </div>
            <p className="text-lg text-gray-600 pt-3">{t.education}</p>
            <div className="space-y-1 text-gray-600 leading-relaxed">
              {/* <p>{t.introduction}</p>  */}
              <p>{t.workDescription}</p>
            </div>
          </div>
        </div>
      </header>

      {/* Social Links Section */}
      <section className="bg-white py-5 md:py-12">
        <div className="container mx-auto px-4">
          <div className="flex flex-col md:flex-row md:flex-wrap justify-center gap-4 max-w-4xl mx-auto">
            {/* 첫 줄: 5개 아이템 */}
            <div className="flex flex-col md:flex-row flex-wrap justify-center gap-4">
              <SocialLink 
                href="https://news.kojunseo.link" 
                icon="newsletter" 
                text={t.newsletter}
                color="#FF6B35"
              />
              <SocialLink 
                href="https://github.com/kojunseo" 
                icon="github" 
                text={t.github}
                color="#333333"
              />
              <SocialLink 
                href="https://scholar.google.com/citations?user=qt7vHIMAAAAJ&hl=ko&oi=ao" 
                icon="scholar" 
                text={t.scholar}
                color="#4285f4"
              />
              <SocialLink 
                href="https://www.youtube.com/channel/UCCaunu3Cv09ZCDxU13Gx3Hg" 
                icon="youtube" 
                text={t.youtube}
                color="#FF0000"
              />
              <SocialLink 
                href="https://www.linkedin.com/in/kojunseo/" 
                icon="linkedin" 
                text={t.linkedin}
                color="#0A66C2"
              />
              <SocialLink 
                href="https://velog.io/@korkite/posts" 
                icon="blog" 
                text={t.blog}
                color="#20C997"
              />
              
            </div>
            {/* 둘째 줄: 3개 아이템 */}
            <div className="flex flex-col md:flex-row justify-center gap-4">
              <SocialLink 
                href="https://kojunseo.github.io/" 
                icon="book" 
                text={t.book}
                color="#2EA44F"
              />
              <SocialLink 
                href="https://share.coffeechat.kr/chato"
                icon="coffee"
                text={t.coffee}
                color="#6366f1"
              />
              <SocialLink 
                href={`mailto:kojunseo@icloud.com?subject=${encodeURIComponent(t.portfolioSubject)}&body=${encodeURIComponent(t.portfolioBody)}`}
                icon="portfolio"
                text={t.portfolio}
                color="#6F4E37"
              />
            </div>
          </div>
        </div>
      </section>

      {/* Featured Video Section */}
      <section className="container mx-auto px-4 py-16">
        <h2 className="text-3xl font-bold text-center mb-8">{t.lectures}</h2>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          <div className="aspect-w-16 aspect-h-9">
            <iframe width="100%" height="100%" src="https://www.youtube.com/embed/hEKcdsrdV8M?si=h1FpOFICQiSggxHQ" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
          </div>
          <div className="aspect-w-16 aspect-h-9">
          <iframe width="560" height="315" src="https://www.youtube.com/embed/iZEJBOEdMEQ?si=qMsGRfNiCRhlOFAD" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
          </div>
          <div className="aspect-w-16 aspect-h-9">
          <iframe width="560" height="315" src="https://www.youtube.com/embed/zxm7WbMEtVg?si=F5UIUt03lQGpO7RD" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
          </div>
        </div>
      </section>

      {/* Projects Section */}
      <section className="bg-white py-16">
        <div className="container mx-auto px-4">
          <h2 className="text-3xl font-bold text-center mb-12">{t.projects}</h2>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
            <ProjectCard
              title={t.project1.title}
              description={t.project1.description}
              tech={t.project1.tech}
            />
            <ProjectCard
              title={t.project2.title}
              description={t.project2.description}
              tech={t.project2.tech}
            />
            <ProjectCard
              title={t.project3.title}
              description={t.project3.description}
              tech={t.project3.tech}
            />
            <ProjectCard
              title={t.project4.title}
              description={t.project4.description}
              tech={t.project4.tech}
              links={t.project4.links}
            />
          </div>
        </div>
      </section>
    </div>
  );
}

export default App;
